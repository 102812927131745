import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import EventCardEdit from "../eventCardEdit/eventCardEdit";

import "./admin.css";

const Admin = () => {
  const storedAccess = localStorage.getItem("hasAccess") === "true";
  const [hasAccess, setHasAccess] = useState(storedAccess);
  const correctPassword = "admin";
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [sidebarActiveClass, setSidebarActiveClass] = useState(1);

  useEffect(() => {
    if (storedAccess) {
      return;
    }

    const userPassword = prompt("Please enter the password:");

    if (userPassword === null) {
      alert("Access denied!");
      return;
    }

    if (userPassword === correctPassword) {
      setHasAccess(true);
      localStorage.setItem("hasAccess", "true");
    } else {
      alert("Access denied!");
    }
  }, [storedAccess, correctPassword]);

  if (!hasAccess) {
    return null;
  }

  function handleMenuBtnClick() {
    setIsSidebarActive((prevIsSidebarActive) => !prevIsSidebarActive);
  }

  return (
    <>
      <Helmet>
        <title>Adminpanel | Polarita</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <section className="main" style={{ minHeight: "unset" }}>
        <div className={`sidebar ${isSidebarActive ? "active" : ""}`}>
          <div className="top">
            <div className="logo">
              <img src=".././images/logo2.svg" alt="Logo" draggable="false"></img>
            </div>
            <i class="bx bx-menu" id="menu_btn" onClick={handleMenuBtnClick}></i>
          </div>

          <ul>
            <li onClick={() => setSidebarActiveClass(1)}>
              <a href="#">
                <i class="bx bx-user-plus"></i>
                <span className="nav_item">Add user</span>
              </a>
            </li>
            <li onClick={() => setSidebarActiveClass(2)}>
              <a href="#">
                <i class="bx bx-data"></i>
                <span className="nav_item">User database</span>
              </a>
            </li>
            <li id="lastchild">
              <a href="#">
                <i class="bx bx-log-out-circle"></i>
                <span className="nav_item">Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="main_content">
          <div className="container">
            {sidebarActiveClass && sidebarActiveClass === 1 && (
              <>
                <section className="section_adduser" style={{ minHeight: "unset" }}>
                  <h5>ADD USER</h5>
                  <hr className="styledHr" style={{ marginTop: ".5rem" }} />

                  <EventCardEdit />
                </section>
              </>
            )}
            {sidebarActiveClass && sidebarActiveClass === 2 && (
              <>
                <section className="section_userdatabase" style={{ minHeight: "unset" }}>
                  <h5>USER DATABASE</h5>
                  <hr className="styledHr" style={{ marginTop: ".5rem" }} />
                  <div class="table-responsive py-5">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Username</th>
                          <th scope="col">Email</th>
                          <th scope="col">Access to</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>polatia@polarita.cz</td>
                          <td>25.03.2023 ( 12:50 )</td>
                          <td>🟢</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>polatia@polarita.cz</td>
                          <td>25.03.2023 ( 12:50 )</td>
                          <td>🟢</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Larry</td>
                          <td>polatia@polarita.cz</td>
                          <td>25.03.2023 ( 12:50 )</td>
                          <td>🔴</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Larry</td>
                          <td>polatia@polarita.cz</td>
                          <td>25.03.2023 ( 12:50 )</td>
                          <td>🟢</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Larry</td>
                          <td>polatia@polarita.cz</td>
                          <td>25.03.2023 ( 12:50 )</td>
                          <td>🔴</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Admin;
