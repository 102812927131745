import React from "react";
import "./navCard.css";

const NavCard = (props) => {
  return (
    <>
      <div className="col-md-3">
        <a href={props.href}>
          <div className="navCard center">
            <h1>{props.title}</h1>
            <div className="center logo_navCard">{/*} <img src=".././images/logoC.svg" draggable="false"></img>{*/}</div>
          </div>
        </a>
      </div>
    </>
  );
};

export default NavCard;
