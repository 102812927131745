import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "../../../src/pages/home/home";
import Admin from "../Admin/admin";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home />} />
        </Routes>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/admyn" element={<Admin />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default AnimatedRoutes;
