import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";

import "./home.css";
import NavCard from "../../components/navCard/navCard";
import NavBar from "../../components/navBar/navBar";
import MemberCard from "../../components/memberCard/memberCard";
import OurProduction from "../../components/ourProduction/ourProduction";
import EventCard from "../../components/eventCard/eventCard";
import AparaturaSlider from "../../components/aparaturaSlider/aparaturaSlider";
import GalleryPage from "../../components/galleryPage/galleryPage";
import Paralax from "../../components/paralax/paralax";
import SongTable from "../../components/songTable/songTable";
import PriceCard from "../../components/priceCard/priceCard";

const imagesAparatura = [".././images/aparatura1.jpeg", ".././images/aparatura7.jpeg", ".././images/gallery/gallery9.jpeg", ".././images/aparatura3.jpeg", ".././images/aparatura4.jpeg", ".././images/aparatura5.jpeg"];

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Svatební skupina</title>
        <meta name="Kapela Polarita" content="Kapela Polarita" />
      </Helmet>
      <section className="page-wrap">
        <section className="first-page">
          <div id="VideoContainer"></div>
          <NavBar />
          <div className="center padding-mp" style={{ paddingTop: "8rem", paddingBottom: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
            <div className="container-fluid">
              <div className="row" style={{ paddingBottom: "2rem" }}>
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  {" "}
                  <div className="center logo-main">
                    <img src=".././images/logo2.svg" draggable="false" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="soc_ico center" style={{ justifyContent: "flex-start", height: "100%", alignItems: "flex-end", flexDirection: "column", marginTop: "5rem", marginRight: "1rem" }}>
                    <a href="https://www.facebook.com/polaritaofficial" target="_blank">
                      <i class="bx bxl-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/polaritamarek/" target="_blank">
                      <i class="bx bxl-instagram"></i>
                    </a>
                    <a href="https://open.spotify.com/artist/2lYBxNY2do2UlgYDRbobGg" target="_blank">
                      <i class="bx bxl-spotify"></i>
                    </a>
                    <a href="https://music.apple.com/cz/artist/polarita-marek-lejhanec/1479549345" target="_blank">
                      <i class="bx bxl-apple"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="center" style={{ flexDirection: "column" }}>
                <h3 style={{ paddingBottom: ".25rem", marginTop: "3rem" }}>
                  <span>Ať Vaše svatba je nezapomenutelná nejen pro Vás. </span>
                </h3>
                <h3>Profesionální kapela Polarita zajišťuje kompletní zábavu pro Vaši svatbu vč DJ - moderování - profesionální světelná i zvuková aparautura.</h3>
              </div>
              <hr className="styledHr" style={{ marginTop: "2rem" }} />
              <div className="carusel center">
                <OurProduction />
              </div>
            </div>
          </div>
        </section>
        <section className="nav-page" style={{ justifyContent: "flex-start" }}>
          <div className="container navcard-container" style={{ padding: "4rem 1.5rem" }}>
            <div className="row">
              <NavCard title="Oslavy a večírky" href="https://kapelapolarita.cz/" />
              <NavCard title="Obsazení kapely" href="#obsazeniKapely" />
              <NavCard title="Galerie" href="#gallery-page" />
              <NavCard title="Cena a rezervace termínu" href="#eventPage" />
              <NavCard title="Repertoár" href="#repertoar" />
              <NavCard title="Aparatura" href="#aparatura" />
              <NavCard title="Naše tvorba" href="https://www.youtube.com/@mareklejha" />
              <NavCard title="Kontakt" href="#contact" />
            </div>
          </div>

          <div className="container" style={{ padding: "4rem 1.5rem" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="aboutUsCard">
                  <h1>Kdo jsme</h1>
                  <hr className="styledHr" style={{ marginBottom: "1rem", width: "90%" }} />
                  <p>Skupina Polarita je složena z profesionálních muzikantů, kteří milují muziku a do vystoupení vždy dají něco víc...</p>
                  <p>Založena byla roku 2015 a ve složení akustická / elektrická kytara, viola, bicí a basa, je beze změn dodnes. </p>
                  <p>Zajišťujeme kompletní ozvučení svatební oslavy, ať už formou zahradní párty, nebo ve velkých sálech. Součástí našich služeb jsou také spolupracující hudební artisti – klávesy, trubky, dechy, vokály.. Všechny naše služby jsou vždy individuálně upraveny tak, aby Vaše svatba byla výjimečná nejen pro Vás, ale i Vaše hosty.</p>
                </div>
              </div>
              <div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
                <div className="image-card">
                  <img src=".././images/band.jpeg" draggable="false" alt="Logo"></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Paralax title="Obsazení Kapely" hrefId="obsazeniKapely" />
        <section className="memberPage" style={{ minHeight: " 145%" }}>
          <div className="center" style={{ flexDirection: "column" }}>
            <div className="container">
              <h1>Složení skupiny Polarita: </h1>
              <div className="row">
                <MemberCard name="Marek Lejhanec" instrument="kytara, zpěv" backgroundImageUrl="images/mareklejhanec.jpg" />
                <MemberCard name="Jiří Doucha" instrument="basa, zpěv" backgroundImageUrl="images/jiridoucha.jpeg" />
                <MemberCard name="Michal Benda" instrument="viola" backgroundImageUrl="images/michalbenda.jpeg" />
                <MemberCard name="Mika Ronos" instrument="bicí" backgroundImageUrl="images/mikaronos.jpg" />
              </div>
            </div>
            <div className="container">
              <h1>Alternace: </h1>
              <div className="row">
                <MemberCard name="Jaromír Hutla" instrument="DJ" backgroundImageUrl="images/hutla.jpg" />
                <MemberCard name="Jiří Kožnar" instrument="klávesy" backgroundImageUrl="images/koznar.png" />
                <MemberCard name="Veronika Hložková" instrument="zpěv" backgroundImageUrl="images/hlozkova.jpg" />
                <MemberCard name="Petr Mifek" instrument="bicí" backgroundImageUrl="images/mifek.png" />
                <MemberCard name="Matouš Novák" instrument="bicí" backgroundImageUrl="images/novak.png" />
              </div>
            </div>
          </div>
        </section>

        <Paralax title="Galerie" hrefId="gallery-page" />
        <section className="gallery-page" style={{ justifyContent: "flex-start", minHeight: "60%" }}>
          <GalleryPage />
        </section>
        <Paralax title="Cena a rezervace termínu" hrefId="eventPage" />
        <section className="eventPage" style={{ padding: "2rem 0 2rem 0", minHeight: "100%" }}>
          <div className="container">
            <div className="row">
              <PriceCard title="Svatba jako víno ..." price="20.000,-Kč" description="Balíček zahrnuje: kapelu Polarita pro zábavu vašich hostů , včetně  koordinace Vašeho svatebního programu na dobu 7h." plus="světelná technika" plus1="zvuková technika" plus2="svatební skupina" plus3="koordinace programu" plus4="doprava v ceně" plus5="program 7h" minus="moderováni" minus1="DJ" />
              <PriceCard title="Svatba jako řemen ..." price="30.000,-Kč" description="Balíček zahrnuje: kapelu Polarita pro zábavu vašich hostů , včetně  koordinace Vašeho svatebního programu na dobu 10h." plus="světelná technika" plus1="zvuková technika" plus2="svatební skupina" plus3="koordinace programu" plus4="doprava v ceně" plus5="program 10h" plus6="moderováni" plus7="DJ noční párty 4h" description1="Polaritní DJ vyplňuje také prostor jednotlivých programů  a zajistí neskutečnou atmosféru do konce Vaší pařby ..." />
              <PriceCard
                title="Svatba když už - tak už ..."
                price="od 40.000,- Kč"
                description="Potřebujete-li 
individuální přístup, ať už bez časového omezeni, možnost bubenické show, zajištění hostujících artist umělců dle Vašeho přání ..., zvolte si tento bonusový balíček.
"
                plus="světelná technika"
                plus1="zvuková technika"
                plus2="svatební skupina"
                plus3="koordinace programu"
                plus4="doprava v ceně"
                plus5="program  min. 10-14h"
                plus6="moderováni"
                plus7="DJ noční párty 8h"
                description1="Polaritní DJ vyplňuje také prostor jednotlivých programů  a zajistí neskutečnou atmosféru do konce Vaší pařby ..."
              />
            </div>

            <hr className="styledHr" style={{ marginBottom: "1rem" }} />

            <h2 className="center">Napište nám a připravíme Vám nabídku na míru. </h2>
            {/*}
            <a href="#contact" className="center">
              <button className="contactUsButton">Kontaktujte nás</button>
            </a>
  {*/}
          </div>
        </section>
        <Paralax title="Repertoár" hrefId="repertoar" />
        <section className="repertoarPage center" style={{ justifyContent: "flex-start", minHeight: "95%" }}>
          <SongTable />
        </section>
        <Paralax title="Aparatura" hrefId="aparatura" />

        <section className="aparatura_page nav-page" style={{ minHeight: "80%" }}>
          <div className="container" style={{ padding: "4rem 1.5rem" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="aboutUsCard">
                  <h1>Bose L1 a Bose F1</h1>
                  <hr className="styledHr" style={{ marginBottom: "1rem" }} />
                  <p>Vlastníme profesionální aparaturu Bose L1 a Bose F1, která ozvučí většinu prostorů našich klientů s výkonem 7KW.</p>
                  <p>Vlastní světelná aparatura je od Chauvet Gigbar 2,0 - 2x sloup.</p>
                  <p>V případě velkých městských akcí máme své zvukaře , stage i světelnou aparaturu.</p>
                  <p>Nezáleží nám na tom, jak velká akce to je, jde o to, aby zvuk byl vždy naprosto ohromující.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image-card">
                  <AparaturaSlider imagesAparatura={imagesAparatura} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*}
        <div id="naseTvorba">
          <div id="paralax-image">
            <div className="paralax">
              <h3>naše tvorba</h3>
            </div>
          </div>
        </div>
        
        <section className="ourProduction_page">
          <div className="carusel center">
            <OurProduction />
          </div>
          <div className="center">
            {" "}
            <iframe style={{ borderRadius: "12px", width: "90%", height: "352px", border: "0", margin: "4rem 0" }} src="https://open.spotify.com/embed/artist/2lYBxNY2do2UlgYDRbobGg?utm_source=generator&theme=0" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </section>
  {*/}
        <section className="contact" id="contact">
          <div className="container" style={{ textAlign: "center" }}>
            <h1>Kontaktujte nás</h1>
            <hr className="styledHr" style={{ marginBottom: "2rem" }} />
            <div className="contact-info center">
              <p>
                <strong>Email:</strong>
                <a href="mailto:Info@kapelapolarita.cz">Info@svatebniskupina.cz</a>
              </p>
              <p>
                <strong>Telefon:</strong> <a href="tel:603559767">+420 603 559 767</a>
              </p>
            </div>
            <div className="social-icons">
              <a href="https://www.facebook.com/polaritaofficial" target="_blank" rel="noopener noreferrer">
                <i className="bx bxl-facebook-circle"></i>
              </a>
              <a href="https://www.instagram.com/polaritamarek/" target="_blank" rel="noopener noreferrer">
                <i className="bx bxl-instagram-alt"></i>
              </a>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Home;
