import React, { Component } from "react";
import "./songTable.css";

class SongTable extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      searchQuery: "",
    };
  }

  componentDidMount() {
    fetch(".././files/repertoar.json")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.repertoar)) {
          this.setState({ data: data.repertoar });
        } else {
          console.error("Data nejsou pole:", data.repertoar);
        }
      })
      .catch((error) => console.error("Chyba při načítání dat:", error));
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  render() {
    const { data, searchQuery } = this.state;

    const filteredData = data.filter((song) => {
      const lowercaseId = typeof song.id === "string" ? song.id.toLowerCase() : String(song.id);
      return song.nazev.toLowerCase().includes(searchQuery.toLowerCase()) || song.autor.toLowerCase().includes(searchQuery.toLowerCase()) || lowercaseId.includes(searchQuery.toLowerCase());
    });
    return (
      <div className="song-table-container">
        <table className="song-table">
          <thead>
            <tr>
              <th style={{ width: "1%", textAlign: "left", border: "unset" }}>#</th>
              <th style={{ width: "99%", border: "unset" }}>
                <div className="center" style={{ justifyContent: "space-between" }}>
                  <span>Název skladby</span>
                  <button>
                    <div className="center">
                      <svg className="css-i6dzq1" stroke-linejoin="round" stroke-linecap="round" fill="none" stroke-width="2" stroke="currentColor" height="20" width="20" viewBox="0 0 24 24">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line y2="13" x2="8" y1="13" x1="16"></line>
                        <line y2="17" x2="8" y1="17" x1="16"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>{" "}
                      <a href=".././files/Repertoár_Polarita.docx">Stáhnout repertoár</a>
                    </div>
                  </button>
                </div>
              </th>
            </tr>
            <tr>
              <th colSpan="2" style={{ padding: "0", background: "transparent" }}>
                <input className="search-input" type="text" placeholder="Vyhledat skladbu..." value={searchQuery} onChange={this.handleSearchChange} />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((song, index) => (
              <tr key={song.id}>
                <td style={{ textAlign: "left" }}>{song.id + "."}</td>
                <td>
                  <div className="song">
                    <img src=".././images/SongImg.png" alt="SongImg" draggable="false" />
                    <div className="Songcontent">
                      <span style={{ fontWeight: "600" }}>{song.nazev}</span>
                      <br />
                      {song.autor}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SongTable;
