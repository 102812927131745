import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../eventCard/eventCard.css";

const EventCardEdit = () => {
  const [eventData, setEventData] = useState([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  const handleEventClick = (index) => {
    setSelectedEventIndex(index);
  };

  const handleRemoveEvent = () => {
    if (selectedEventIndex !== null) {
      const updatedEvents = eventData.filter((event, index) => index !== selectedEventIndex);
      setEventData(updatedEvents);
      setSelectedEventIndex(null);
      saveEventData(updatedEvents);
    }
  };

  const handleAddEvent = () => {
    const newEvent = {
      month: "",
      date: "",
      year: "",
      day: "",
      time: "",
      place: "",
    };
    const updatedEvents = [...eventData, newEvent];
    setEventData(updatedEvents);
    setSelectedEventIndex(updatedEvents.length - 1);
    saveEventData(updatedEvents);
  };

  const handleMoveEvent = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(eventData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setEventData(items);
    saveEventData(items);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedEventData = { ...eventData[selectedEventIndex] };
    updatedEventData.month = event.target.elements.month.value;
    updatedEventData.date = event.target.elements.date.value;
    updatedEventData.year = event.target.elements.year.value;
    updatedEventData.day = event.target.elements.day.value;
    updatedEventData.time = event.target.elements.time.value;
    updatedEventData.place = event.target.elements.place.value;

    const events = [...eventData];
    events[selectedEventIndex] = updatedEventData;

    const updatedEvents = [...eventData];
    setEventData(updatedEvents);

    try {
      const response = await fetch("http://localhost:3001/api/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ events }),
      });

      if (response.ok) {
        console.log("Data successfully saved");
      } else {
        console.error("Failed to save data:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const saveEventData = async (events) => {
    try {
      const response = await fetch("http://localhost:3001/api/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ events }),
      });

      if (response.ok) {
        console.log("Data successfully saved");
      } else {
        console.error("Failed to save data:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <DragDropContext onDragEnd={handleMoveEvent}>
      <Droppable droppableId="eventList">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {eventData.map((event, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div className="event" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onClick={() => handleEventClick(index)}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="event-card-wrap">
                            <div className="event-details">
                              <h3>{event.month}</h3>
                              <h1>{event.date}</h1>
                              <h3>{event.year}</h3>
                            </div>
                            <div className="event-details center" style={{ flexDirection: "column" }}>
                              <h3>
                                {event.day}
                                {" - "}
                                {event.time}
                              </h3>
                              <h1>{event.place}</h1>
                              <h3>POLARITA</h3>
                            </div>
                            <div className="event-card-image"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Původní inputy */}
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Month"
                      name="month"
                      value={event.month}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[index] };
                        updatedEventData.month = e.target.value;
                        setEventData([...eventData.slice(0, index), updatedEventData, ...eventData.slice(index + 1)]);
                      }}
                    />
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Date"
                      name="date"
                      value={event.date}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[index] };
                        updatedEventData.date = e.target.value;
                        setEventData([...eventData.slice(0, index), updatedEventData, ...eventData.slice(index + 1)]);
                      }}
                    />
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Year"
                      name="year"
                      value={event.year}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[index] };
                        updatedEventData.year = e.target.value;
                        setEventData([...eventData.slice(0, index), updatedEventData, ...eventData.slice(index + 1)]);
                      }}
                    />
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Day"
                      name="day"
                      value={event.day}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[index] };
                        updatedEventData.day = e.target.value;
                        setEventData([...eventData.slice(0, index), updatedEventData, ...eventData.slice(index + 1)]);
                      }}
                    />
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Time"
                      name="time"
                      value={event.time}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[index] };
                        updatedEventData.time = e.target.value;
                        setEventData([...eventData.slice(0, index), updatedEventData, ...eventData.slice(index + 1)]);
                      }}
                    />
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Place"
                      name="place"
                      value={event.place}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[index] };
                        updatedEventData.place = e.target.value;
                        setEventData([...eventData.slice(0, index), updatedEventData, ...eventData.slice(index + 1)]);
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {/* Přidání a odebrání koncertů */}
      <button type="submit" className="submit_button" onClick={handleSubmit}>
        Uložit
      </button>
      <button type="button" className="remove_button" onClick={handleRemoveEvent}>
        Odebrat koncert
      </button>
      <button type="button" className="add_button" onClick={handleAddEvent}>
        Přidat koncert
      </button>
    </DragDropContext>
  );
};

export default EventCardEdit;
