import React, { useState, useEffect } from "react";

import "./galleryPage.css";

import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const GalleryPage = () => {
  const images = [1, 3, 2, 4, 6, 7, 5, 8, 9, 10, 11, 12,13,14,15,16].map((id) => ({
    src: `.././images/gallery/gallery${id}.jpeg`,
  }));

  return (
    <>
      <div className="Gallery-page">
        <section id="GalleryCard">
          <div className="container-fluid">
            <div className="slideshow">
              <Carousel
                images={images}
                style={{ height: 650, width: 1500 }}
                playIcon={false}
                pauseIcon={false}
                leftIcon={<i class="bx bx-chevron-left"></i>}
                rightIcon={<i class="bx bx-chevron-right"></i>}
                maxIcon={<i class="bx bx-expand-alt"></i>}
                minIcon={<i class="bx bx-x"></i>}
                objectFit={"contain"}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GalleryPage;
