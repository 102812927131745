import React from "react";
import "./priceCard.css";

const PriceCard = (props) => {
  return (
    <>
      <div className="col-md-4">
        <div className="priceCard center">
          <div class="card">
            <div class="header">
              <span class="title">{props.title}</span>
              {/*}	<span class="price">Cena: {props.price}</span>{*/}
            </div>
            <p class="desc">{props.description}</p>
            <ul class="lists">
              {props.plus ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus}</span>
                </li>
              ) : null}
              {props.plus1 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus1}</span>
                </li>
              ) : null}
              {props.plus2 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus2}</span>
                </li>
              ) : null}
              {props.plus3 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus3}</span>
                </li>
              ) : null}
              {props.plus4 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus4}</span>
                </li>
              ) : null}
              {props.plus5 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus5}</span>
                </li>
              ) : null}
              {props.plus6 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus6}</span>
                </li>
              ) : null}
              {props.plus7 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="plusCheck" fill-rule="evenodd" d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.plus7}</span>
                </li>
              ) : null}
              {props.minus ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="minusCheck" fill-rule="evenodd" d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.minus}</span>
                </li>
              ) : null}
              {props.minus1 ? (
                <li class="list">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path className="minusCheck" fill-rule="evenodd" d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" clip-rule="evenodd"></path>
                  </svg>
                  <span>{props.minus1}</span>
                </li>
              ) : null}
            </ul>
            <p class="desc1">{props.description1}</p>
            <a href="#contact">
              <button type="button" class="action">
                Kontaktujte nás
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceCard;
