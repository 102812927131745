import React from "react";
import "./paralax.css";

const Paralax = (props) => {
  return (
    <>
      <div id={props.hrefId}>
        <div id="paralax-image">
          <hr className="styledHr" style={{ marginBottom: "0rem" }} />
          <div className="paralax">
            <h3>{props.title}</h3>
          </div>
          <hr className="styledHr" style={{ marginBottom: "0rem" }} />
        </div>
      </div>
    </>
  );
};

export default Paralax;
